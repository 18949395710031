import React from 'react'

const Admin_Dashborad = () => {
  return (
    <div>
        <h1>Welcome to the admin panel</h1>
    </div>
  )
}

export default Admin_Dashborad