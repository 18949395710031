import React from 'react'

const PrivacyPolicy = () => {
    return (
        <div>

            <div className="container d-flex flex-column align-items-center justify-content-center surf_container mt-3 pt-4">
                <div className="row">
                    <div className="col text-center">
                        <h1 className=" surf_text"> <span className='text_span'> Privacy Policy</span></h1>
                    </div>
                </div>
            </div>

            <div className="container d-flex flex-column  surf_container mt-3 pt-3">
                <div className="row">
                    <div className="col">
                        <h1 className="surf_text">
                            <span className="text_span fs-1">KiteActive.com Privacy Policy</span>
                        </h1>
                        <p className="surf_para fs-6 mt-3" >
                            This Website hosts this page and collects some Personal Data from its Users.

                            This document can be printed for reference using the print command in the settings of any browser.
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <h1 className="surf_text">
                            <span className="text_span fs-1">Owner and Data Controller</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >
                            Felix Maks – KiteActive – Schans 12A, 8531 DW Lemmer
                        </p>
                        <p className="surf_para mt-2">
                            <strong>Owner contact email:</strong>info@kiteactive.com
                        </p>

                        <h1 className="surf_text">
                            <span className="text_span fs-1">Types of Data Collected</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >
                            Among the types of Personal Data that this Website collects, by itself or through third parties, there are: Trackers; Usage Data; Data communicated while using the service; first name; last name; date of birth; phone number; physical address; country; email address; postal code; city; Billing information; various types of Data; unique device identifiers for advertising (Google Advertiser ID or IDFA, for example); gender.
                        </p>
                        <p className="surf_para fs-6 mt-2">
                            Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by specific explanation texts displayed prior to the Data collection.
                            Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when using this Website.
                            Unless otherwise specified, all Data requested by this Website is mandatory and failure to provide this Data may make it impossible for this Website to provide its services. In cases where this Website specifically states that some Data is not mandatory, Users are free not to communicate this Data without consequences to the availability or the functioning of the Service.
                            Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner.
                            Any use of Cookies – or of other tracking tools – by this Website or by the owners of third-party services used by this Website serves the purpose of providing the Service required by the User, in addition to any other purposes described in this document and in the Cookie Policy, if available.

                            De Gebruikers zijn verantwoordelijk voor Persoonsgegevens van derden die worden verkregen, gepubliceerd of gedeeld door deze Website en zij bevestigen dat zij toestemming van de derde partij hebben om de Gegevens aan de Eigenaar te verstrekken.
                        </p>



                        <h1 className="surf_text">
                            <span className="text_span fs-1">Method and place of data processing</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >
                            The Owner takes appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data.
                            The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Owner, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of this Website (administration, sales, marketing, legal, system administration) or external parties (such as third-party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Owner at any time.
                        </p>



                        <h1 className="surf_text">
                            <span className="text_span fs-1">
                                Legal basis for processing
                            </span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >
                            Users have given their consent for one or more specific purposes; Note: In some jurisdictions the Owner may be allowed to process Personal Data until the User objects to such processing (“opt-out”), without requiring consent or any other of the following legal grounds. However, this does not apply where European data protection law applies to the processing of Personal Data;
                            the provision of Data is necessary for the performance of an agreement with the User and/or for any pre-contractual obligations thereof;
                            the processing is necessary for compliance with a legal obligation to which the Owner is subject;
                            the processing is related to a task carried out in the public interest or in the exercise of official authority vested in the Owner;
                        </p>

                        <h1 className="surf_text">
                            <span className="text_span fs-1">Location</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >
                            The Data is processed at the Owner's operating offices and in any other places where the parties involved in the processing are located.

                            Depending on the User's location, data transfers may involve transferring the User's Data to a country other than their own. To find out more about the place of processing of such transferred Data, Users can check the section containing details about the processing of Personal Data.

                            Users are also entitled to learn about the legal basis of Data transfers to a country outside the European Union or to any international organization governed by public international law or set up by two or more countries, such as the UN, and about the security measures taken by the Owner to safeguard their Data.

                            If any such transfer takes place, Users can find out more by checking the relevant sections of this document or inquire with the Owner using the information provided in the contact section.
                        </p>

                        <h1 className="surf_text">
                            <span className="text_span fs-1">Retention time</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >
                            Personal data will be processed and stored for as long as necessary for the purpose for which they were collected.

                            Therefore the following applies:

                            Personal Data collected for purposes related to the performance of a contract between the Owner and the User shall be retained until such contract has been fully performed.
                            Personal Data collected for the purposes of the Owner’s legitimate interests shall be retained as long as needed to fulfill such purposes. Users may find specific information regarding the legitimate interests pursued by the Owner within the relevant sections of this document or by contacting the Owner.
                            The Owner may be allowed to retain Personal Data for a longer period whenever the User has given consent to such processing, as long as such consent is not withdrawn. Furthermore, the Owner may be obliged to retain Personal Data for a longer period whenever required to do so for the performance of a legal obligation or upon order of an authority.

                            Once the retention period expires, Personal Data shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after expiration of the retention period.
                        </p>

                        <h1 className="surf_text">
                            <span className="text_span fs-1">The purpose of the processing</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >
                            The Data concerning the User is collected to allow the Owner to provide its Service, comply with its legal obligations, respond to enforcement requests, protect its rights and interests (or those of its Users or third parties), detect any malicious or fraudulent activity, as well as the following: Analytics, Interaction with live chat platforms, Contacting the User, Displaying content from external platforms, Advertising, Managing contacts and sending messages, User database management, Remarketing and behavioral targeting, Backup storage and management, Processing productivity related activities and Tag Management.

                            For specific information about the Personal Data used for each purpose, the User may refer to the section “Detailed information on the processing of Personal Data”.
                        </p>

                        <h1 className="surf_text">
                            <span className="text_span fs-1">Detailed information about the processing of Personal Data</span>
                        </h1>
                        <strong>Advertising:</strong>
                        <p className="surf_para fs-6 m-0" >
                            This type of service allows User Data to be used for advertising communication purposes. These communications are displayed in the form of banners and other advertisements on this Website, possibly based on User interests.
                            It does not mean that all Personal Data are used for this purpose. Information and conditions of use are shown below.
                            Some of the services listed below may use Trackers to identify Users or they may use the behavioral retargeting technique, i.e. displaying ads based on the User’s interests and behavior, including those detected outside of this Website. For more information, please refer to the privacy policies of the relevant services.
                            These types of services usually offer a possibility to opt out of such tracking. In addition to any opt-out function offered by one of the services below, Users can find more information about how to opt out of interest-based advertising in general in the “How to Opt Out of Interest-Based Advertising” section of this document.
                        </p>

                        <p className="surf_para fs-6 mt-4">
                            Google Ad Manager is an advertising service provided by Google Ireland Limited that allows the Owner to run advertising campaigns in combination with external advertising networks that the Owner, unless otherwise specified in this document, has no direct relationship with. If you want to know more about Google's use of Data, please visit  Google's partner policy .
                            <ul>
                                <li>This service uses the “DoubleClick” Cookie, which tracks use of this Website and User behavior concerning ads and products and services offered.</li>
                                <li> Users may choose to opt out of all DoubleClick cookies by going to:  Ads Settings .</li>
                                <li>Personal Data processed: Usage Data; Trackers.</li>
                                <li>Processing location: Ireland –  Privacy Policy .</li>
                            </ul>

                        </p>

                        <strong>Analyses :</strong>
                        <p className="surf_para fs-6 mt-0" >
                            The services contained in this section enable the Owner to monitor and analyze web traffic and can be used to keep track of User behavior.
                        </p>

                        <p className="surf_para fs-6 mt-3">
                            Google Analytics is a web analytics service provided by Google Ireland Limited (“Google”). Google utilizes the Data collected to track and examine the use of this Website, to compile reports on its activities and share them with other Google services.
                            Google may use the Data collected to contextualize and personalize the ads of its own advertising network.
                            This integration of Google Analytics anonymizes your IP address. It works by shortening Users' IP addresses within member states of the European Union or in other contracting states to the Agreement on the European Economic Area. Only in exceptional cases will the full IP address be sent to a Google server and shortened within the US.

                            Personal Data processed: Usage Data; Trackers.
                        </p>


                        <strong> User Database Management :</strong>
                        <p className="surf_para fs-6 mt-3">
                            This type of service allows the Owner to create user profiles from an email address, a personal name, or other information the User provides to this Website and to track the User activities through analytics features. This Personal Data may also be linked to publicly available information about the User (such as social network profiles) and used to build private profiles that the Owner can display and use to improve this Website.
                            Some services may also allow the sending of periodic messages to the User, such as emails based on specific actions performed on this Website.
                        </p>

                        <p className="surf_para fs-6 mt-3">
                            <ul>
                                <li>  ZOHO CRM (ZOHO Corporation B.V)</li>
                                <li> ZOHO CRM is a user database management service provided by ZOHO Corporation BV</li>
                                <li>Personal Data processed: billing information; date of birth; country; city; phone number; various types of Data as specified in the privacy policy of the service; last name; email address; first name.</li>
                                <li>
                                    Processing location: Netherlands –  Privacy Policy .</li>
                            </ul>
                        </p>

                        <strong>Contacting the User :</strong>
                        <p className="surf_para fs-6 mt-3">
                            By filling in the contact form with their Data, the User authorizes this Website to use these details to reply to requests for information, quotes or any other kind of request as indicated by the form’s header.
                        </p>
                        <p className="surf_para fs-6 mt-3">
                            By registering for the mailing list or for the newsletter, the User's email address will be added to the contact list of those who may receive email messages containing information of commercial or promotional nature concerning this Website. Your email address might also be added to this list as a result of signing up to this Website or after making a purchase.

                            Personal Data processed: date of birth; email address; city; last name; date of birth; Usage Data; gender; country; ZIP/Postal code; phone number; Trackers; first name.
                        </p>



                        <strong>Manage contacts and send messages :</strong>
                        <p className="surf_para fs-6 mt-3">
                            This type of service allows the management of a database of email contacts, phone contacts or other contacts for the purpose of communicating with the User.
                            These services may also collect data concerning the date and time when the message was viewed by the User, as well as when the User interacted with it, such as by clicking on links included in the message.
                        </p>

                        <p className="surf_para fs-6 mt-3">
                            <ul>
                                <li> ZOHO Campaigns is an email address management and messaging service provided by ZOHO Corporation BV</li>
                                <li> Personal Data processed: email address; billing information; physical address; date of birth; country; city; last name;</li>

                                <li>
                                    Processing location: Netherlands –  Privacy Policy .</li>
                            </ul>
                        </p>


                        <strong>Backup Storage and Management :</strong>
                        <p className="surf_para fs-6 mt-3">
                            This type of service allows the Owner to store and manage backups of this Website on remote servers managed by the service provider itself. Backups may include source code and content as well as the data the User provides to this Website.

                        </p>

                        <p className="surf_para fs-6 mt-3">
                            <ul>
                                <li> Google Drive is a backup storage and management service provided by Google Ireland Limited.</li>

                                <li>
                                    Personal Data processed: various types of Data as specified in the privacy policy of the service.</li>
                                <li> Processing location: Ireland –  Privacy Policy .</li>
                            </ul>
                        </p>


                        <strong>Remarketing and behavioral advertising :</strong>
                        <p className="surf_para fs-6 mt-3">
                            This type of service allows this Website and its partners to inform, optimize and serve advertising based on past use of this Website by the User.
                            This activity is facilitated by tracking Usage Data and by using Trackers. This information is transferred to the partners that manage the remarketing and behavioral targeting activity.

                            These types of services usually offer a possibility to opt out of such tracking. In addition to any opt-out offered by one of the services below, Users can find more information about how to opt out of interest-based advertising in general in the “How to Opt Out of Interest-Based Advertising” section of this document.

                        </p>

                        <p className="surf_para fs-6 mt-3">
                            <ul>
                                <li>To learn more about Google's use of Data, please visit  Google's partner policy .</li>

                                <li>
                                    Personal Data processed: Usage Data; Trackers.</li>
                                <li> Processing location: Ireland –  Privacy Policy  –  ​​Opt Out ..</li>
                            </ul>
                        </p>




                        <strong>Tag control :</strong>
                        <p className="surf_para fs-6 mt-3">
                            This type of service helps the Owner to manage the tags or scripts needed on this Website in a centralized manner.
                            This enables Users' Data to flow through these services, potentially resulting in the retention of this Data.
                        </p>

                        <p className="surf_para fs-6 mt-3">
                            <ul>
                                <li>Google Tag Manager is a tag management service provided by Google Ireland Limited.</li>

                                <li>    Personal Data processed: Usage Data; Trackers.</li>
                                <li> Processing location: Ireland –  Privacy Policy.</li>
                            </ul>
                        </p>


                        <strong>Processing of productivity related activities :</strong>
                        <p className="surf_para fs-6 mt-3">
                            This type of service assists the Owner in managing tasks, collaboration and productivity-related activities in general. When using this type of service, Users' Data will be processed and may be stored depending on the purpose of the activity in question.
                            These services may integrate with a wide range of third-party services disclosed within this privacy policy, which allow the Owner to import or export Data necessary for the activity in question.
                        </p>

                        <p className="surf_para fs-6 mt-3">
                            <ul>
                                <li>Google Docs is an online text editing and collaboration service provided by Google Ireland Limited.</li>

                                <li>Personal Data processed: email address; date of birth; Data communicated while using the service; gender; city; phone number; first name; last name.</li>
                                <li> Google Sheets is an online spreadsheet and collaboration service provided by Google Ireland Limited.</li>
                                <li> Processing location: Ireland –  Privacy Policy .</li>
                            </ul>
                        </p>


                        <strong>Displaying content from external platforms:</strong>
                        <p className="surf_para fs-6 mt-3">
                            This type of service allows you to view content hosted on external platforms directly from the pages of this Website and interact with them.
                            If this type of service is installed, it might still collect web traffic data for the pages where the service is installed, even when Users do not use it.
                        </p>

                        <p className="surf_para fs-6 mt-3">
                            <ul>
                                <li>Google Fonts is a typeface visualization service provided by Google Ireland Limited that allows this Website to incorporate content of this kind on its pages.</li>

                                <li>Personal Data processed: Usage Data; Trackers.</li>
                                <li> Processing location: Ireland –  Privacy Policy .</li>
                            </ul>
                        </p>


                        <h1 className="surf_text">
                            <span className="text_span fs-1">Information about opting out of interest-based advertising</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >In addition to any opt-out possibilities provided by the services listed in this document, Users may find information about how to opt out of interest-based advertising in general by reading the relevant section of the Cookie Policy.

                        </p>
                        <h1 className="surf_text">
                            <span className="text_span fs-1">User Rights</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >Users may exercise certain rights regarding their Data processed by the Owner.
                            <ul>
                                <li><strong>To withdraw their consent at any time</strong> Users have the right to withdraw their consent where they have previously given their consent to the processing of their Personal Data.</li>
                                <li> <strong>Object to processing of their Data. </strong>Users have the right to object to processing of their Data if the processing is carried out on a legal basis other than consent. More information can be found in the specific section below. </li>
                                <li> <strong> Access their Data.</strong>Users have the right to learn if the Owner is conducting the Data processing, obtain disclosure regarding certain aspects of the processing and obtain a copy of the Data undergoing processing. </li>
                                <li><strong>To verify and request correction.   </strong>Users have the right to verify the accuracy of their Data and to request that it be updated or corrected.</li>
                                <li><strong> To restrict the processing of their Data. </strong>Users have the right, under certain circumstances, to restrict the processing of their Data. In this case, the Owner will not process their Data for any purpose other than storing it.</li>

                                <li> <strong> To have their Personal Data erased or otherwise removed</strong>Users have the right, under certain circumstances, to obtain the erasure of their Data from the Owner.</li>
                                <li> <strong>To receive their Data and have it transmitted to another Controller.   </strong>Users have the right to receive their Data in a structured, commonly used and machine-readable format and, if technically feasible, to transmit that Data to another controller without hindrance. This provision is applicable provided that the Data is processed by automated means and that the processing is based on the User’s consent, on a contract to which the User is a party or on pre-contractual obligations arising therefrom.</li>
                                <li> <strong> To lodge a complaint.</strong></li>
                                <li> <strong> </strong>Users have the right to lodge a claim with their competent data protection authority.</li>
                            </ul>

                        </p>


                        <h1 className="surf_text">
                            <span className="text_span fs-1">More information about the right to object to processing</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >Where Personal Data is processed for a public interest, in the exercise of an official authority vested in the Owner or for the purposes of the legitimate interests pursued by the Owner, Users may object to such processing by providing a ground related to their particular situation to justify the objection.

                            Users must know that, however, should their Personal Data be processed for direct marketing purposes, they can object to that processing at any time without providing any justification. To learn, whether the Owner is processing Personal Data for direct marketing purposes, Users may refer to the relevant sections of this document.
                        </p>

                        <h1 className="surf_text">
                            <span className="text_span fs-1">How to exercise these rights</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >Any requests to exercise User rights can be directed to the Owner through the contact details provided in this document. These requests can be exercised free of charge and will be addressed by the Owner as early as possible and always within one month.
                        </p>
                        <h1 className="surf_text">
                            <span className="text_span fs-1">Cookie Policy</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >This Website uses Trackers. To learn more, the User may consult the  Cookie Policy .
                        </p>
                        <h1 className="surf_text">
                            <span className="text_span fs-1">Additional information about Data collection and processing</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >The User's Personal Data may be used for legal purposes by the Owner in Court or in the stages leading to possible legal action arising from improper use of this Website or the related Services.
                            The User declares to be aware that the Owner may be required to reveal personal data upon request of public authorities.
                        </p>

                        <h1 className="surf_text">
                            <span className="text_span fs-1">Definitions and legal framework</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >Any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.  </p>

                        <strong>Usage data :</strong>
                        <p className="surf_para fs-6 m-0">Information collected automatically through this Website (or third-party services employed in this Website), which can include: the IP addresses or domain names of the computers utilized by the Users who use this Website, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Application) and the details about the path followed within the Application with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment.</p>


                        <strong>User :</strong>
                        <p className="surf_para fs-6 m-0">The individual using this Website who, unless otherwise specified, coincides with the Data Subject.</p>

                        <strong>Person concerned :</strong>
                        <p className="surf_para fs-6 m-0">The natural person to whom the Personal Data relates..</p>

                        <strong>Data Processor (or Data Supervisor):</strong>
                        <p className="surf_para fs-6 m-0">Data Processor (or Data Supervisor)</p>

                        <strong>Data Controller (or Owner)</strong>
                        <p className="surf_para fs-6 m-0">The natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of Personal Data, including the security measures concerning the operation and use of this Website. The Data Controller, unless otherwise specified, is the Owner of this Website.</p>

                        <strong>This Website (or this Application)</strong>
                        <p className="surf_para fs-6 m-0">The means by which the User's Personal Data is collected and processed.   </p>

                        <strong>Employe</strong>
                        <p className="surf_para fs-6 m-0">The service provided by this Website as described in the relative terms (if available) and on this site/application.  </p>
                        <strong>European Union (or EU)</strong>
                        <p className="surf_para fs-6 m-0">Unless otherwise stated, all references in this document to the European Union include all current member states of the European Union and the European Economic Area.</p>
                        <strong>Cookies</strong>
                        <p className="surf_para fs-6 m-0">Cookies are Trackers consisting of small sets of data stored in the User's browser. </p>
                        <strong>Legal information</strong>
                        <p className="surf_para fs-6 m-0">This privacy statement has been drawn up on the basis of provisions of various legislation, including Article 13/14 of the European Regulation 2016/679 (General Data Protection Regulation).
                            <p lassName="surf_para fs-6 m-0">This privacy policy relates solely to this Website, unless otherwise stated within this document.</p>
                        </p>


                    </div>
                </div>
            </div>

        </div>
    )
}

export default PrivacyPolicy
