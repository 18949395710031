export const Carddata_head_section = [
    {
        title: {
            en: "Your choice - which camp is your flavour?",
            gr: "Ihre Wahl – welches Camp ist Ihr Favorit?",
            du: "Uw keuze - welk kamp is uw favoriet?"
        },
        subtitle: {
            en: "Kiteactive",
            gr: "Kiteactive",
            du: "Kiteactive"
        },
        subtitle1: {
            en: "- for Adventures: Our Top Camps of 2024!",
            gr: "- für Abenteuer: Unsere Top-Camps 2024!",
            du: "- voor avonturen: Onze populairste kampen van 2024!"
        }
    }
];
