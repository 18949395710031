import React from 'react'
import '../../Pages/Pages.css';

import { Helmet } from 'react-helmet'

import headerbackimg from '../../Images/con_img.webp'
import lifeimg from '../../Images/img4.webp'
import icon1 from '../../Images/mail.webp'
import icon2 from '../../Images/whatsapp.webp'
import icon3 from '../../Images/phone-call.webp'


const mainContainer = {
    title: {
        en: "Contact us and make your surf dream a reality",
        gr: "Kontaktiere uns und verwirkliche deinen Surftraum", // German (GR)
        du: "Neem contact met ons op en maak je surfdroom werkelijkheid" // Dutch (DU)
    },
    para: {
        en: "KiteActive is your gateway to the ultimate kitesurfing adventure, where exhilaration meets luxury. Our expert instructors craft tailor-made lessons and clinics at some of the world’s most stunning kitesurfing destinations. Whether you’re a beginner or a seasoned rider, we’ve scouted the globe for the best locations to ensure an unforgettable experience on the water.",
        gr: "KiteActive ist dein Tor zum ultimativen Kitesurfabenteuer, wo Aufregung auf Luxus trifft. Unsere erfahrenen Instruktoren gestalten maßgeschneiderte Lektionen und Kliniken an einigen der atemberaubendsten Kitesurfziele der Welt. Egal, ob du ein Anfänger oder ein erfahrener Fahrer bist, wir haben die besten Standorte weltweit ausgesucht, um ein unvergessliches Erlebnis auf dem Wasser zu gewährleisten.", // German (GR)
        du: "KiteActive is jouw toegang tot het ultieme kitesurfavontuur, waar opwinding luxe ontmoet. Onze deskundige instructeurs bieden op maat gemaakte lessen en clinics aan op enkele van de meest adembenemende kitesurfbestemmingen ter wereld. Of je nu een beginner bent of een doorgewinterde rijder, we hebben wereldwijd gezocht naar de beste locaties om een onvergetelijke ervaring op het water te garanderen." // Dutch (DU)
    },

    title2: {
        en: "Does something feel unclear?",
        gr: "Fühlt sich etwas unklar an?", // German (GR)
        du: "Voelt er iets onduidelijk?" // Dutch (DU)
    },

    title4: {
        en: "Get in Touch!",
        gr: "Nehmen Sie Kontakt auf!", // German (GR)
        du: "Neem contact op!" // Dutch (DU)
    },

    title5: {
        en: "Free rebooking up to 14 days prior to arrival",
        gr: "Kostenlose Umbuchung bis 14 Tage vor der Ankunft", // German (GR)
        du: "Gratis omboeken tot 14 dagen voor aankomst" // Dutch (DU)
    },

    lifestylepara: {
        en: "A professional kite team, on the beach and behind the computer. We organize your kitesurfing trip down to the last detail. We look forward to meeting you and having a great time together! Whether you're a beginner looking to learn the basics or an experienced kitesurfer seeking the thrill of new adventures, our team is here to support you every step of the way. From selecting the best locations with optimal wind conditions to arranging accommodations and equipment, we handle it all.",
        gr: "Ein professionelles Kite-Team, am Strand und hinter dem Computer. Wir organisieren deine Kitesurfreise bis ins kleinste Detail. Wir freuen uns darauf, dich zu treffen und gemeinsam eine großartige Zeit zu haben! Egal, ob du ein Anfänger bist, der die Grundlagen lernen möchte, oder ein erfahrener Kitesurfer, der den Nervenkitzel neuer Abenteuer sucht, unser Team ist hier, um dich auf jedem Schritt des Weges zu unterstützen. Vom Auswählen der besten Standorte mit optimalen Windbedingungen bis hin zur Organisation von Unterkünften und Ausrüstung, wir kümmern uns um alles.", // German (GR)
        du: "Een professioneel kite team, op het strand en achter de computer. We organiseren je kitesurftrip tot in de kleinste details. We kijken ernaar uit om je te ontmoeten en samen een geweldige tijd te hebben! Of je nu een beginner bent die de basis wil leren of een ervaren kitesurfer die op zoek is naar de opwinding van nieuwe avonturen, ons team is er om je bij elke stap te ondersteunen. Van het selecteren van de beste locaties met optimale windomstandigheden tot het regelen van accommodaties en uitrusting, wij regelen het allemaal." // Dutch (DU)
    }
};


const Contactus = ({ selectedLanguage }) => {

    return (
        <div>
            {/* For seo  */}

            <Helmet>
                <title>Contact Us - KiteActive: Your Kitesurfing Experts </title>

                <meta name="description" content="Get in touch with KiteActive for all your kitesurfing needs. Our team is here to help with lessons, gear, and more. Contact us today!." />

                <meta name="keywords" content="kite surfing, kitesurfing trips, kitesurf spots, kite surfing lessons, kitesurfing lessons worldwide, kitesurf vacation, kite travel, kitesurf holiday" />

                <link rel="canonical" href="https://www.kiteactiveventures.com/contact" />

            </Helmet>


            <div className="page_cont">
                <img src={headerbackimg} className='headerbackimg' alt="" />
            </div>


            {/* image background content  */}

            <div className='main_container'>
                <h1 className='contact_text'> {mainContainer.title[selectedLanguage]}</h1>
                <p className='contact_para'>{mainContainer.para[selectedLanguage]} </p>
                <div className=" fw-bold  text-center fs-4 mt-5">
                    {mainContainer.title2[selectedLanguage]}
                </div>
                <h1 className='contact_text'>{mainContainer.title4[selectedLanguage]}</h1>
            </div>

            <div className=' container crd_main_div'>
                <div className="row text-center">
                    <div className="col-lg-4">
                        <a href="mailto:info@kiteactive.com" className="nav-link">
                            <div className="card crd_div text-center">
                                <img src={icon1} className="card_icon m-auto w-25" alt="Email Icon" />
                                <div className="crd_title m-3">Email</div>
                                <div className="crd_title2">Click on the icon to submit</div>
                            </div>
                        </a>
                    </div>
                    <div className="col-lg-4">
                        <a href="https://wa.me/31850091325?text=Hello" className=" text-decoration-none text-warning" title="Call us on WhatsApp">
                            <div className="card crd_div">
                                <img src={icon2} className='card_icon m-auto w-25' alt="" />
                                <div className="crd_title m-3">Whatsapp</div>
                                <div className="crd_title2">+31850091325</div>
                            </div>
                        </a>
                    </div>
                    <div className="col-lg-4">
                        <a href="tel:+31852127915" className="text-decoration-none">
                            <div className="card crd_div text-center" role="button" style={{ cursor: "pointer" }}>
                                <img src={icon3} className="card_icon m-auto w-25" alt="Phone Icon" />
                                <div className="crd_title m-3">Phone</div>
                                <div className="crd_title2">+31 85 212 7915</div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div className='container mt-5  pt-5 '>
                <div className='row mt-3'>
                    <div className='col-md-6'>
                        <div className='lifestyle_div'>
                            <img src={lifeimg} className='lifestyle' alt="lifestyle" />
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <div className="life_style_div">
                            <div className="">
                                <h1 className="contact_text2">
                                    {mainContainer.title5[selectedLanguage]}
                                </h1>
                            </div>
                            <div className="">
                                <h1 className="contact_para2">
                                    <p> {mainContainer.lifestylepara[selectedLanguage]}</p>
                                </h1>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default Contactus
