import React from 'react'

const Teamrider = () => {
    return (
        <div>


            <div className="container d-flex flex-column align-items-center justify-content-center surf_container mt-3 pt-3">
                <div className="row">
                    <div className="col">
                        <h1 className="surf_text">
                            <span className="text_span fs-1"> KiteActive Team rider</span>
                        </h1>

                        <p className="surf_para fs-6 mt-3" >
                            Een dikke sponsor deal binnen slepen? Misschien kan dat wel bij KiteActive 🙂 Wij zijn op zoek naar kitesurf talent! Omdat veel van onze instructeurs wedstrijden hebben gevaren en veel ervaring hebben binnen het kitesurfen en verschillende disciplines vinden wij het super belangrijk om ervoor te zorgen dat er ook weer een nieuwe generatie aan kite talent wordt gepusht!
                        </p>

                        <h1 className="surf_text">
                            <span className="text_span fs-1"> Waarom KiteActive jou perfecte sponsor is</span>
                        </h1>

                        <p className="surf_para fs-6 mt-3" >
                            <ul>
                                <li>Wij organiseren toffe gezamelijke trainingsdagen om elkaar te pushen, hier zullen professionele fotografen bij zijn om dikke content van jou te schieten wat je
                                    gratis kunt gebruiken.</li>
                                <li>Je hebt een doel binnen het kitesurfen wat je graag wil behalen. Dit kan van een knetter gek avontuur zijn tot nummer 1 worden op Red bull King of the air.
                                    Wat is jou droom?</li>
                                <li>Je levert content aan KiteActive en maakt jou fanbase enthousiast over het aller mooiste kitemerk: KiteActive!</li>
                                <li>Woon je in het buitenland? Geen enkel probleem!</li>
                            </ul>
                        </p>


                        <h1 className="surf_text">
                            <span className="text_span fs-1"> Wat verwachten wij van jou?</span>
                        </h1>

                        <p className="surf_para fs-6 mt-3" >
                            <ul>
                                <li>Je bent sociaal, enthousiast en 100% verslaafd aan… Kite-surfen</li>
                                <li>Je hebt een doel binnen het kitesurfen wat je graag wil behalen. Dit kan van een knetter gek avontuur zijn tot nummer 1 worden op Red bull King of the air. Wat is jou droom?</li>
                                <li>Je levert content aan KiteActive en maakt jou fanbase enthousiast over het aller mooiste kitemerk: KiteActive!</li>
                                <li>Woon je in het buitenland? Geen enkel probleem!</li>
                            </ul>
                        </p>




                    </div>
                </div>

            </div>
        </div>
    )
}

export default Teamrider
