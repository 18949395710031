import React from 'react'

const Termcondition = () => {
    return (
        <div>

            <div className="container d-flex flex-column  surf_container mt-3 pt-4">
                <div className="row">
                    <div className="col">
                        <h1 className=" surf_text fs-1"> <span className='text_span'> KITEACTIVE TRAVEL CONDITIONS</span></h1>

                        <p className="surf_para fs-6 mt-3" >
                            If you make a booking with KiteActive™, this means that you agree to the terms and conditions of KiteActive™. It is therefore important that you read these conditions carefully before booking. In addition, KiteActive™ assumes that you have read the travel information on the website.
                        </p>
                        <h1 className=" surf_text fs-1"> <span className='text_span'>COMPANY INFORMATION</span></h1>

                        <p className="surf_para fs-6 mt-3" >
                            <ul>
                                <li> <strong> Name : </strong> KiteActive.com ™</li>
                                <li> <strong>Address : </strong> Schans 12A   8531 DW in Lemmer</li>
                                <li> <strong> Chamber of Commerce</strong> 72054670</li>
                                <li> <strong> VAT NUMBER: </strong> NL213042745B02</li>
                                <li> <strong> IBAN: </strong> NL37 KNAB 0613 5129 87</li>
                                <li> <strong> BIC/SWIFT: </strong> KNABNL2H  </li>
                                <li> <strong> Bank : </strong>Knab</li>
                                <li> <strong> Tel : </strong>+31 085 009 1325 (office NL)</li>
                                <li> <strong> E-mail: : </strong>info@kiteactive.com</li>
                                <li> <strong> Internet: : </strong>kiteactiveventures.com/</li>                           
                            </ul>
                        </p>




                        <h1 className="surf_text">
                            <span className="text_span fs-1">CANCEL</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >
                        A cancellation must always be reported by e-mail within three days of the event why the trip must be canceled in whole (or in part). Please note that a cancellation is never free of charge.  You are only entitled to a (partial) refund of the travel sum if you have taken out cancellation insurance and for a valid reason.  KiteActive™ can only process a cancellation if it has been submitted by email by the lead booker. A fellow traveler is not jointly and severally liable and therefore cannot report a cancellation. KiteActive always tries to find a suitable solution, but can never be held liable for your cancellation.
                        </p>
                       



                        <h1 className="surf_text">
                            <span className="text_span fs-1">PUBLICATION</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >
                        Images, maps and information are provided by partners, tourist offices and professional photographers. It may happen that photos of the accommodation do not match your booked room. KiteActive™ is used in many hotel rooms and accommodation places. KiteActive™ cannot be held liable for this. Any errors in a brochure, route, advertisement or any other publication will be corrected on the KiteActive™ website, so that you can always find the most current and correct information.
                        </p>



                        <h1 className="surf_text">
                            <span className="text_span fs-1">
                            PRIVACY
                            </span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >
                        KiteActive™ needs your details to book a trip, request a brochure or become a member of the newsletter. Your data will be treated with care by KiteActive™. Your data will not be provided to third parties.
                        </p>

                        <h1 className="surf_text">
                            <span className="text_span fs-1">PRICES</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >
                        KiteActive™ works with fluctuating prices, which are based on supply and demand. As a result, it may sometimes happen that the trip you have booked is later offered cheaper or perhaps more expensive. In addition, we often work with last-minute offers and offers. This means that prices can fluctuate during the season. The current prices can be found at www.kiteactive.com.
                        </p>

                        <h1 className="surf_text">
                            <span className="text_span fs-1">BOOK AND PAY</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >
                        Once you have provided all information and agreed to the terms and conditions, KiteActive™ considers this a final booking. 48 hours after your booking, KiteActive has received a 40% deposit, otherwise we can still cancel the trip.  </p>
                        <h1 className="surf_text">
                            <span className="text_span fs-1">TRAVEL MEDIATION</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >
                        We are an intermediary for flights and other parts of the trip. We will mention these parts separately on the invoice and the same conditions of this organization apply.</p>
                        <h1 className="surf_text">
                            <span className="text_span fs-1">SEVERAL AND SEVERAL LIABLE</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >
                        The person who books a trip is jointly and severally liable (also for the fellow travelers he/she registers with KiteActive™). </p>
                        <h1 className="surf_text">
                            <span className="text_span fs-1">BOOKING MINORS WITH KITEACTIVE™</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >
                        By making the booking, the minor traveler declares that he has received permission from parents and/or guardians. </p>
                        <h1 className="surf_text">
                            <span className="text_span fs-1">AMENDMENTS</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >
                        After drawing up the invoice, you can make changes up to 28 days before departure (provided the desired change is still possible). For each change, a €10 change fee will be charged plus any additional costs incurred as a result of the change. </p>

                        <h1 className="surf_text">
                            <span className="text_span fs-1">CANCELLATION OF A TRIP BY KITEACTIVE</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >
                        Some trips require a minimum participation. This trip can still be canceled or moved up to a few days before departure. Keep this in mind!! </p>

                        <h1 className="surf_text">
                            <span className="text_span fs-1">PAY</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >
                        The down payment is 40% of the total travel sum. This amount must be paid immediately after booking. The costs of any cancellation insurance must also be paid immediately. The remaining amount must be received by KiteActive™ no later than 30 days before departure. If you book within 30 days before departure, you must transfer the total amount in one go. Are you not doing this on time? Then we can cancel your booking. </p>
                        <h1 className="surf_text">
                            <span className="text_span fs-1">THE CONDITIONS FOR A SHARED ROOM</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >
                        If you book a shared room with us, you can stay in someone else’s room. Of course we make a distinction between ladies and gentlemen. Would you rather not share a room? Then book a single room! (at extra cost) or find a friend who wants to come along so you can share a room together.</p>
                        <h1 className="surf_text">
                            <span className="text_span fs-1">TRAVEL PAPERS</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >
                        Once KiteActive™ has received full payment, we will send the travel documents by email approximately one week before departure. Here you will find all information regarding the trip. If you would like to receive your travel documents by post, a shipping fee of €15 will be charged. You must check the information on the (flight) tickets and vouchers for accuracy. If something does not match what you booked, you must contact KiteActive™ as soon as possible.

</p>
                        <h1 className="surf_text">
                            <span className="text_span fs-1">TRAVEL DOCUMENTS</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >
                        It is prohibited to travel with an expired passport or identity card. In some countries your passport is still valid for a certain number of months. You have to find this out yourself. If you do not have Dutch nationality, you should always check with the embassy of the country you want to travel to whether you need a visa. It is always your own responsibility to have the correct travel documents. KiteActive™ cannot be held liable for this.</p>
                        <h1 className="surf_text">
                            <span className="text_span fs-1">NO COOLING-DOWN PERIOD</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >
                        We would like to point out that bookings you make via the internet are final. The so-called ‘cooling-off period’, which is referred to in the law as ‘distance purchasing’, does not apply to holiday trips that you book via the KiteActive™ website. </p>
                        <h1 className="surf_text">
                            <span className="text_span fs-1">INJURY</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >
                        The participant of this trip is at all times liable for all damage caused by the participant to material, the organization, KiteActive™ as well as damage caused to third parties. The participant is also liable at all times for damage caused to the site or any vegetation or furniture present.</p>
                        <h1 className="surf_text">
                            <span className="text_span fs-1">PARTICIPATE AT YOUR OWN RISK</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >
                        The participant is aware that there are risks involved when participating in the (kitesurfing) packages. Participation is entirely at your own risk. The participant declares that he agrees with this statement and that, upon booking, he cannot hold the organization liable for damage in any form whatsoever. Participation in (kitesurfing) packages can only take place if this statement has been signed or agreed to by each participant when booking. </p>
                        <h1 className="surf_text">
                            <span className="text_span fs-1">EXCLUSION FROM PARTICIPATION IN THE EVENT OF MISCONDUCT</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >
                        In the event of misconduct or failure to follow instructions from the organization, the participant can be excluded from further participation in the (kitesurfing) packages without a refund of the travel price. Misconduct is assessed by the organization. </p>
                        <h1 className="surf_text">
                            <span className="text_span fs-1">COPYRIGHT</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >
                        Copyright 2011 KiteActive™. Nothing from the KiteActive™ website or brochure may be copied, reproduced and/or made public by means of printing, photocopying, microfilm or in any other way without the prior written permission of KiteActive™. </p>
                        <h1 className="surf_text">
                            <span className="text_span fs-1">DISSATISFIED OR COMPLAINTS</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >
                        If you have any complaints about the trip or are dissatisfied with the accommodation, you must immediately report this to the KiteActive™ representative on site. Or contact our head office so that immediate action can be taken. When you get home it is often too late, so let us know in time if something is not going as it should. </p>
                        <h1 className="surf_text">
                            <span className="text_span fs-1">GOVERNING LAW & DISPUTES</span>
                        </h1>
                        <p className="surf_para fs-6 m-0" >
                        Copyright 2011 KiteActive™. Nothing from the KiteActive™ website or brochure may be copied, reproduced and/or made public by means of printing, photocopying, microfilm or in any other way without the prior written permission of KiteActive™. </p>







                    </div>
                </div>
            </div>

        </div>
    )
}

export default Termcondition
