import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays, parseISO, isSameDay, isWithinInterval } from 'date-fns';
import axios from 'axios';
import './DatePickerStyles.css';
import config from '../config/config';

const Datepicker = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    tripName,
    selectedDuration,
    counts,
    totalPrice,
    totalCount,
    levels,
    carRentalSelections,
    carRentalPrice,
    lineItems,
  } = location.state;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [validDates, setValidDates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [earliestValidDate, setEarliestValidDate] = useState(null); // Track the earliest valid date

  // Fetch all date ranges using Axios and filter based on tripName and selectedDuration
  const fetchDateRanges = async () => {
    try {
      const response = await axios.post(`${config.API_BASE_URL}/api/admin/getall_date_ranges`);
      const data = response.data;

      // Filter for the selected tripName and duration
      const filteredDates = data.getAllTripDateRange
        .filter((trip) => trip.tripName === tripName && trip.days === selectedDuration)
        .flatMap((trip) =>
          trip.dateRanges.map((range) => ({
            startDate: parseISO(range.startDate),
            endDate: parseISO(range.endDate),
            status: range.status,
          }))
        );

      setValidDates(filteredDates);

      // Find the earliest available start date
      const earliestDate = filteredDates.reduce((earliest, current) => {
        return !earliest || current.startDate < earliest ? current.startDate : earliest;
      }, null);

      setEarliestValidDate(earliestDate);
      setStartDate(earliestDate); // Set the initial start date to the earliest valid date
    } catch (error) {
      console.error("Error fetching date ranges:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tripName && selectedDuration) {
      fetchDateRanges();
    }
  }, [tripName, selectedDuration]);

  const availableStartDates = validDates.map((date) => date.startDate);

  // Handle start date selection (only for available dates)
  const handleDateChange = (date) => {
    const selected = validDates.find(({ startDate }) => isSameDay(startDate, date));

    if (selected) {
      if (selected.status < totalCount) {
        alert(`Only ${selected.status} are available for this date.`);
        return;
      }
      setStartDate(date);
      const calculatedEndDate = addDays(date, getDurationInDays(selectedDuration));
      setEndDate(calculatedEndDate);
    } else {
      alert("Please select a valid date.");
    }
  };

  // Duration in days based on the selectedDuration directly
  const getDurationInDays = (selectedDuration) => {
    const durationInDays = parseInt(selectedDuration.split(' ')[0], 10);
    return durationInDays - 1; // Assuming the duration includes the start day
  };

  // Function to navigate to the final page with all data
  const handleNextPage = () => {
    if (startDate && endDate) {
      navigate(`/${tripName}/checkout/room`, {
        state: {
          tripName,
          selectedDuration,
          counts,
          totalPrice,
          totalCount,
          levels,
          startDate,
          endDate,
          carRentalSelections,
          carRentalPrice,
          lineItems,
        },
      });
    } else {
      alert("Please select a valid date.");
    }
  };

  // Add a custom CSS class to highlight start dates and set the status text
  const highlightStartDate = (date) => {
    const foundDate = validDates.find(({ startDate }) => isSameDay(startDate, date));

    if (foundDate) {
      if (foundDate.status === 0) {
        return "booked-date";
      } else if (foundDate.status >= 1 && foundDate.status <= 3) {
        return "few-left-date";
      } else if (foundDate.status > 3) {
        return "available-date";
      }
    }

    if (
      startDate &&
      endDate &&
      isWithinInterval(date, { start: startDate, end: endDate })
    ) {
      return "selected-range-date";
    }

    return "";
  };

  // button disable 
  const isButtonDisabled = !(startDate && endDate);

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize(); // Check on initial render
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);


  return (
    <div>
      {loading ? (
        <div>Loading date ranges...</div>
      ) : (
        <div className="container-fluid date_container">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8 m-0 p-0">
              <div className="m-auto text-center m-0 p-0">
                <p className="text-center person_week">
                  <span>
                    {totalCount} Person/ {selectedDuration}/{" "}
                  </span>
                  {levels && levels.length > 0 ? (
                    <span>
                      {levels.map((level, index) => (
                        <span key={index}>{level.name}, </span>
                      ))}
                    </span>
                  ) : (
                    <p>No levels selected.</p>
                  )}
                </p>

                <div className="datepick_border"></div>
                <div className="date_visible">
                  <i className="fa fa-circle cir1" aria-hidden="true"></i>
                  <span className="date_visible_span"> Available </span>
                  <i className="fa fa-circle cir2" aria-hidden="true"></i>
                  <span className='date_visible_span'> Fully Booked</span>
                  <i className="fa fa-circle cir3" aria-hidden="true"></i>
                  <span className=''> Last spots</span>
                </div>
                <div className="datepicker_cont">
                  <div className="custom-datepicker">
                    <h2 className="cal_head mb-4">Select Start Date</h2>
                    <DatePicker
                      selected={startDate}
                      onChange={handleDateChange}
                      inline
                      monthsShown={isMobile ? 1 : 2}
                      dateFormat="yyyy/MM/dd"
                      placeholderText="Select Start Date"
                      dayClassName={highlightStartDate}
                      className="custom-datepicker"
                      includeDates={availableStartDates}
                      minDate={earliestValidDate || new Date()} // Ensure the calendar starts from the earliest valid date
                      openToDate={earliestValidDate} // Ensure the calendar starts from the first available date
                      shouldCloseOnSelect={false} // Keep the calendar open after selecting a date
                    />
                  </div>
                </div>

                {startDate && endDate && (
                  <div className="fw-bold cal_head">
                    <span> {startDate.toLocaleDateString()} to </span>
                    <span> {endDate.toLocaleDateString()}</span>
                  </div>
                )}

              </div>

              <div className="btn_container">
                <button
                  onClick={handleNextPage}
                  className="date_pick_btn fixed-bottom"
                  disabled={isButtonDisabled}
                  style={{
                    backgroundColor: isButtonDisabled ? "gray" : "#ffc800",
                    cursor: isButtonDisabled ? "not-allowed" : "pointer",
                    color: isButtonDisabled ? "darkgray" : "white",
                  }}
                >
                  Next
                </button>
              </div>

            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Datepicker;

